var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"},[_vm._v(" Пациенты "),_c('v-btn',{staticClass:"pa-5 px-sm-10 white--text",attrs:{"color":"#d86568","large":"","rounded":""},on:{"click":_vm.createPatient}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1)],1),_c('object-grid-advanced',{attrs:{"title":"Пациенты","value":_vm.patients_with_responsible_info,"show-actions":{ edit: true, delete: true },"field-defs":[
      { text: 'Пациент', value: 'fio', width: '45%' },
      { text: 'Ответственный', value: 'user_full_name', width: '43%' },
    ],"hide-toolbar":false,"instance-id":"company_patientSearch"},on:{"itemSelected":(v) => (this.selected = v),"doEditItem":(item) =>
        _vm.$router.push({
          name: 'company-patient',
          params: {
            patient_id: String(item.id),
          },
        }),"doDeleteItem":_vm.deletePatient}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }